<template>
  <div>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>Historial de asistencias</h3>
            <div class="text-muted text-body-1">
              Historal de asistencia por docente, fecha y horario.
            </div>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 pt-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debes seleccionar el docente, horario y fecha
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        :label="
                          !filteredTeachers.length > 0
                            ? 'Cargando docentes'
                            : 'Docentes'
                        "
                        :loading="!filteredTeachers.length > 0"
                        filled
                        rounded
                        dense
                        clearable
                        :items="filteredTeachers"
                        item-text="first_name"
                        item-value="id_user"
                        v-model="report.teacherId"
                        @change="get(report.teacherId)"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span
                              >{{ item.first_name }} {{ item.last_name }}</span
                            >
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content class="mx-n4 pl-3">
                            <v-row>
                              <v-col cols="10" lg="11">
                                <v-list-item-title>
                                  <span class="font-weight-medium">
                                    {{ item.first_name }}
                                  </span>

                                  <span class="font-weight-medium">
                                    {{ item.last_name }}
                                  </span>
                                </v-list-item-title></v-col
                              >
                            </v-row>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->
            </v-col>
          </v-row>
          <div v-if="teacherSchedule.isLoadingTeacherSchedule">
            <v-sheet class="mx-auto mt-5 py-10 px-4" elevation="0">
              <p class="text-center font-weight-bold text-h6">
                Cargando horarios del docente
              </p>
              <v-row class="mt-5">
                <v-col class="mx-auto" cols="12" md="6" lg="4" xl="3">
                  <v-progress-linear
                    indeterminate
                    color="blue darken-2"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-sheet>
          </div>
          <v-row v-if="!!teacherSchedule.data.length > 0">
            <v-col>
              <v-card elevation="0" outlined>
                <v-tabs>
                  <v-tab v-for="day in days.data" :key="day.id">
                    {{ day.name }}
                  </v-tab>

                  <v-tab-item v-for="day in days.data" :key="day.id">
                    <v-card>
                      <v-card-text>
                        <v-list two-line class="pa-0 ma-0">
                          <v-list-item-group>
                            <v-list-item
                              v-for="(schedule, index) in getScheduleByDay(
                                day.id
                              )"
                              :key="index"
                              @click="
                                selectSchedule(schedule);
                                dialog = !dialog;
                              "
                            >
                              <v-list-item-content :key="schedule.idschedule">
                                <v-list-item-title
                                  ><b> {{ schedule.subject }} </b>
                                  <v-chip
                                    color="primary"
                                    class="white--text mx-3"
                                    small
                                  >
                                    <span
                                      >{{ schedule.grade }} -
                                      {{
                                        !!schedule.speciality
                                          ? schedule.speciality
                                          : schedule.section
                                      }}
                                      {{ schedule.group }} <span v-if="!!schedule.speciality">-</span>
                                      {{
                                        !!schedule.technicalSection
                                          ? schedule.technicalSection.section
                                          : ""
                                      }}</span
                                    >
                                  </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  >{{ schedule.day.name }}
                                  {{ schedule.block }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div>
      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-title class="text-h5 primary white--text">
                Fecha
              </v-card-title>

              <v-card-text class="mt-10">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Fecha de asistencia"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      filled
                      rounded
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined text @click="dialog = false">
                  Cerrar
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  :loading="report.isLoading"
                  @click="getAttandanceHistory()"
                >
                  Obtener listado
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>
    <AttendanceRecordModal
      ref="mdlAttendanceRecord"
      :schedule="selectedSchedule"
      :attendance="attendanceList"
    ></AttendanceRecordModal>
  </div>
</template>

<script>
import ChangeStatusBaseModal from "@/components/modals/ChangeStatusBaseModal.vue";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
  FETCH_USERS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import schedulesRepository from "../../../repositories/schedulesRepository";
import studentClassRepository from "../../../repositories/studentClassRepository";
import AttendanceRecordModal from "@/components/modals/student_class/AttendanceRecordModal.vue";
import daysRepository from "../../../repositories/daysRepository";

export default {
  name: "student_class_record",
  title: "Historial Asistencia | GE ITR",
  components: {
    ChangeStatusBaseModal,
    AttendanceRecordModal,
  },
  data() {
    return {
      activeTab: 0,
      dialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      report: {
        teacherId: "",
        isLoading: false,
      },
      teacherSchedule: {
        data: [],
        isLoadingTeacherSchedule: false,
      },
      menu: false,
      selectedSchedule: {},
      attendanceList: {},
      days: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Historial de asistencia", route: "student_class_record" },
    ]);
    this.$store.dispatch(FETCH_USERS);
    /*Acá se hace referencia al permiso de las acciones - Despues se creará el de zona*/
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Tipos de duración");
    this.getDays();
  },
  methods: {
    selectSchedule(obj) {
      this.selectedSchedule = { ...obj };
    },
    unSelectSchedule() {
      this.selectedSchedule = {};
    },
    teacherData(id) {
      return this.teachers.find((item) => item.id_user == id);
    },
    getScheduleTeacher() {
      this.teacherSchedule.isLoadingTeacherSchedule = true;
      schedulesRepository
        .getTeacherSchedule(this.report.teacherId)
        .then(({ data }) => {
          this.teacherSchedule.data = data.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.teacherSchedule.isLoadingTeacherSchedule = false;
        });
    },
    getAttandanceHistory() {
      this.report.isLoading = true;
      studentClassRepository
        .getAttendanceBySchedule(
          this.selectedSchedule.idschedule,
          this.selectedSchedule.iduser,
          this.date.substr(0, 10)
        )
        .then(({ data }) => {
          this.attendanceList = data;
          if (this.attendanceList.data.length == 0) {
            this.fireToast({
              icon: "info",
              title:
                "No tiene listado registrado en la fecha y horario seleccionado",
            });
          } else {
            this.showMdlAttendanceRecordModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.report.isLoading = false;
        });
    },
    getDays() {
      daysRepository
        .getAllDay()
        .then(({ data }) => {
          this.days = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Error al obtener los datos desde el servidor",
          });
        });
    },
    get(teacher) {
      if (teacher != null) {
        this.getScheduleTeacher();
        return;
      }
      this.teacherSchedule.data = [];
      this.teacherSchedule.isLoadingTeacherSchedule = false;
    },
    getScheduleByDay(dayId) {
      return this.teacherSchedule.data.filter(
        (schedule) => schedule.day.id === dayId
      );
    },
    showMdlAttendanceRecordModal() {
      this.$refs.mdlAttendanceRecord.toggleModal();
    },
  },
  computed: {
    teachers() {
      let userTypes = [];
      // if (process.env.APP_ENV === "local") {
      //   userTypes.push("Superadministrador");
      //   userTypes.push("Superadmin");
      // } else {
      //   userTypes.push("Docente académico");
      //   userTypes.push("Docente técnico");
      // }
      userTypes.push("Docente académico");
      userTypes.push("Docente técnico");
      userTypes.push("Coordinación técnica"); // TODO: Cambiar por el tipo de usuario que corresponda a Eduardo barrera ya que se agregó para que el pudiera dar clases, pero no debería tener ese tipo de usuario
      return this.$store.getters.getUsersByUserType(userTypes);
    },

    filteredTeachers() {
      return this.teachers.filter(
        (item) => item.first_name.toLowerCase() || item.last_name.toLowerCase()
      );
    },
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue) {
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
    },
  },
};
</script>
